import { template as template_fc8c77d33b0e44128c418b1aa3ccfb27 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_fc8c77d33b0e44128c418b1aa3ccfb27(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
